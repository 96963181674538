import { decodeJwt } from "@/utils/decode-jwt.js";

const state = {
  isThermal: false,
};

const actions = {};

const mutations = {
  setIsThermal(state, value) {
    state.setIsThermal = value;
  },
};

export default {
  state,
  actions,
  mutations,
};
