import axios from "axios";
import Global from "./Global";
import getCookie from "@/utils/get-cookie";
import { isProduction } from "@/utils/env.js";
import { ElMessage } from "element-plus";

axios.defaults.headers.common["X-CSRFToken"] = getCookie(
  `${isProduction ? "" : "staging"}csrftoken`
);
axios.defaults.withCredentials = true;
axios.defaults.baseURL = window.runtimeConfig?.AFP_SERVER_URL;

let messageInstance = null;
let syncingInProgress = false;
let isRefreshing = false;
let refreshSubscribers = [];

const Request = {
  createMethod: (method) => (url, data, contentType = "application/json", accept = Global.accept) => {
    return RequestHandler.handleRequest(url, data, method, contentType, accept);
  },
  post: (...args) => Request.createMethod("POST")(...args),
  get: (...args) => Request.createMethod("GET")(...args),
  patch: (...args) => Request.createMethod("PATCH")(...args),
  put: (...args) => Request.createMethod("PUT")(...args),
  delete: (...args) => Request.createMethod("DELETE")(...args),
  get_file: (url, data) => {
    return RequestHandler.handleFileRequest(url, data, "POST");
  }
};

const handleInvalidToken = () => {
    ElMessage.error({
      message:
        "Token expired. Please go log in again to generate a new token.",
      showClose: true,
    });
    setTimeout(() => {
      window.location.href = `${window.runtimeConfig?.AFP_URL}login`;
    }, 2500);
}

const refreshToken = async () => {
  if (isRefreshing) {
    return new Promise(resolve => refreshSubscribers.push(resolve));
  }

  isRefreshing = true;
  try {
   const response = await axios({
      method: 'POST',
      url: "/authentication/api/v1/refresh/token/"
    });
    isRefreshing = false
    refreshSubscribers.forEach((callback) => callback(response))
    refreshSubscribers = []

    return response
  } catch (error) {
    isRefreshing = false
    refreshSubscribers = []
    handleInvalidToken()
    throw error
  }
};

const RequestHandler = {
  executeRequest: async (requestConfig, retryOnServerError = true) => {
    const makeRequest = () => axios(requestConfig);

    try {
      return await makeRequest();
    } catch (error) {
      if (error.response?.status === 401) {
        try {
          await refreshToken();
          return await makeRequest();
        } catch (refreshError) {
          throw refreshError;
        }
      }
      else if (retryOnServerError &&
              (!navigator.onLine || error.response?.status >= 500)) {
        return await retryRequest(makeRequest);
      }
      throw error;
    }
  },

  handleRequest: (url, data, method, contentType, accept) => {
    const requestConfig = {
      method,
      url,
      data,
      headers: {
        Accept: accept || Global.accept,
        "Content-Type": contentType,
      },
      withCredentials: true
    };

    return RequestHandler.executeRequest(requestConfig);
  },

  handleFileRequest: (url, data, method) => {
    const requestConfig = {
      method,
      url,
      data,
      responseType: "blob",
      withCredentials: true
    };

    return RequestHandler.executeRequest(requestConfig, false);
  },
};

const retryRequest = async function (promise) {
  try {
    const response = await promise();
    if (syncingInProgress) {
      messageInstance.close();
      messageInstance = ElMessage.success({
        message: "Syncing completed successfully.",
      });
      syncingInProgress = false;
    }
    return response;
  } catch (error) {
    if (
      !navigator.onLine ||
      (error.response && error.response.status >= 500)
    ) {
      if (!syncingInProgress) {
        messageInstance = ElMessage.warning({
          message: "Syncing in progress. Please don't close this window.",
          duration: 0,
        });
        syncingInProgress = true;
      }
      await new Promise((resolve) => setTimeout(resolve, 3000));
      return await retryRequest(promise);
    }
    throw error;
  }
};

export default Request;
