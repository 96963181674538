<template>
  <div class="main-wrapper">
    <div class="pannel-wrapper">
      <div class="solar-wrapper">
        <div class="solar-wrapper-header">
          <img
            v-if="!anonymousUser"
            src="@/assets/icons/newEyeOpenIcon.svg"
            alt="eye"
            class="eye"
            @click="changeShowFlag"
            v-show="areasAreShown"
          />
          <img
            v-if="!anonymousUser"
            src="@/assets/icons/newEyeClosedIcon.svg"
            alt="eye"
            class="eye-closed"
            @click="changeShowFlag"
            v-show="!areasAreShown"
          />
          <div
            class="section-header-right-side"
            @click="toggleSolars"
            :class="{ inactiveGray: !areasAreShown }"
          >
            <p class="cluster-title">{{ $t("sidebar.solar") }}</p>
            <img
              src="@/assets/icons/newArrowUp.svg"
              alt="triangle"
              class="arrow"
              v-show="showSolars"
            />
            <img
              src="@/assets/icons/newArrowDown.svg"
              alt="triangle"
              class="arrow"
              v-show="!showSolars"
            />
          </div>
        </div>
        <div v-if="showSolars" class="solar-details-box">
          <div
            class="solar-inside-wrapper"
            v-for="(solar, index) in completedAreas"
            :key="index"
          >
            <div
              class="solar-header"
              :class="{
                active: index == selectedSolar && solar.show,
                grayClass: !areasAreShown,
                expand: index == selectedSolar,
              }"
            >
              <div @click="showSolarDetails(solar, index)" class="left-text">
                <p>
                  {{ $t("sidebar.pvArray") }} <span>{{ index + 1 }}</span>
                </p>
              </div>
              <div
                class="anotation-image-wrapper"
                :class="{
                  'hidden-solars':
                    !solar.show && areasAreShown && !anonymousUser,
                  'shown-solars': solar.show && areasAreShown && !anonymousUser,
                }"
              >
                <img
                  src="@/assets/icons/newTrashIcon.svg"
                  alt="trash"
                  class="trash"
                  v-if="showTrashIcon && solar.show && !anonymousUser"
                  @click="openDeleteModal(solar)"
                />

                <img
                  src="@/assets/icons/newEyeOpenIcon.svg"
                  alt="eye"
                  class="eye"
                  @click="hideThisSolarArea(solar, index)"
                  v-if="solar.show && areasAreShown && !anonymousUser"
                />
                <img
                  src="@/assets/icons/newEyeClosedIcon.svg"
                  alt="eye"
                  class="eye-closed"
                  @click="showThisSolarArea(solar, index)"
                  v-if="!solar.show && areasAreShown && !anonymousUser"
                />
                <img
                  src="@/assets/icons/newEyeOpenIcon.svg"
                  alt="eye"
                  class="eye"
                  @click="hideThisSolarArea(solar, index)"
                  v-if="solar.show && !areasAreShown && !anonymousUser"
                />
                <img
                  src="@/assets/icons/newEyeClosedIcon.svg"
                  alt="eye"
                  class="eye-closed"
                  @click="showThisSolarArea(solar, index)"
                  v-if="!solar.show && !areasAreShown && !anonymousUser"
                />
              </div>
            </div>
            <div class="solar-details" v-if="index == selectedSolar">
              <div class="details-text">
                <p>{{ $t("sidebar.outlineArea") }}</p>
                <span
                  >{{
                    $n(Number(calculateSurfaceArea(solar)), "decimal")
                  }}m²</span
                >
              </div>
              <div class="details-text">
                <p>{{ $t("sidebar.amountofpanels") }}</p>
                <span>{{ getAreaPanelsCount(solar) }}</span>
              </div>
              <div class="details-text">
                <p>{{ $t("sidebar.panelsArea") }}</p>
                <span>
                  {{ $n(calculateVisiblePanelsArea(solar), "decimal") }}m²
                </span>
              </div>
              <div class="details-text">
                <p>{{ $t("sidebar.slope") }}</p>
                <span>{{ $n(solar.angle, "decimal") }}°</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <RemoveSolarGroupModal
      :isVisible="removeModalOpen"
      :areaIndex="selectedAreaIndex"
      @confirm="confirmDeleteSolarGroup"
      @close-modal="closeRemoveModal"
      @update:isVisible="removeModalOpen = $event"
    />
  </div>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import RemoveSolarGroupModal from "@/components/RightSidebar/RemoveSolarGroupModal.vue";

import API from "@/api/API";

const props = defineProps([
  "completedAreas",
  "solarGroupDetails",
  "hideAreas",
  "showAreas",
  "hideSingleArea",
  "showSingleArea",
  "sample",
  "config",
  "setVisibilityInStore",
  "objectIsloaded",
  "removeSolarGroup",
  "unselectSolarArea",
  "anonymousUser",
  "getAreaPanelsCount",
  "calculateVisiblePanelsArea",
  "calculateSurfaceArea",
  "active",
  "removeSelectedArea",
]);
const emits = defineEmits(["updateSelectedArea", "setColapsedSections"]);

const areasAreShown = ref(
  !(
    props.solarGroupDetails.every((v) => v.show === true) ||
    props.solarGroupDetails.every((v) => v.show === false)
  )
);
const showTrashIcon = ref(true);

const changeShowFlag = () => {
  if (props.active === 2 && areasAreShown.value) {
    return;
  }

  const newValue = !areasAreShown.value;

  props.setVisibilityInStore("solar_groups_visible", newValue);

  if (newValue) {
    props.showAreas();
    showTrashIcon.value = true;
  } else {
    props.hideAreas();
    showTrashIcon.value = false;
    selectedSolar.value = null;
    // TODO: uncomment this when we finish other tickets, but it might not be needed
    // props.unselectSolarArea(true);
  }

  areasAreShown.value = newValue;
};

function hideThisSolarArea(solar, index) {
  if (areasAreShown.value) {
    props.hideSingleArea(solar, true);
  }
  if (selectedSolar.value == index) {
    selectedSolar.value = null;
  }
  const newValue = !solar.show;
  setSolarAreaAppearance(solar.id, newValue);
  solar.show = newValue;
  props.unselectSolarArea(solar);
}

function showThisSolarArea(solar, index) {
  if (areasAreShown.value) {
    props.showSingleArea(solar);
  }
  const newValue = !solar.show;
  setSolarAreaAppearance(solar.id, newValue);
  solar.show = newValue;
}

const showSolars = ref(false);
const selectedSolar = ref(null);

function toggleSolars() {
  if (props.solarGroupDetails.length) {
    showSolars.value = !showSolars.value;
  }
  if (showSolars.value) {
    emits("setColapsedSections", "solarComponent");
    selectedSolar.value = null;
  } else {
    props.unselectSolarArea();
  }
}
function colapseFromParent() {
  if (props.solarGroupDetails.length) {
    showSolars.value = false;
    if (selectedSolar.value != null) {
      props.unselectSolarArea();
    }
  }
}

function showSolarDetails(solar, number) {
  if (!solar.show || !areasAreShown.value) {
    return;
  }
  if (selectedSolar.value != number) {
    props.unselectSolarArea();

    emits("updateSelectedArea", solar);
    selectedSolar.value = number;
  } else {
    selectedSolar.value = null;
    props.unselectSolarArea();
  }
}

async function setSolarAreaAppearance(id, show) {
  if (props.sample) {
    return;
  }
  return await API.airteam3DViewer.patchObject(id, show);
}

function handleCreateSolarGroup() {
  areasAreShown.value = true;
  if (props.solarGroupDetails.length) {
    showSolars.value = true;
  }
  if (showSolars.value) {
    emits("setColapsedSections", "solarComponent");
    props.setVisibilityInStore("solar_groups_visible", showSolars.value);

    const lastSolarGroup =
      props.solarGroupDetails[props.solarGroupDetails.length - 1];
    showThisSolarArea(lastSolarGroup, props.solarGroupDetails.length - 1);
    showSolarDetails(lastSolarGroup, props.solarGroupDetails.length - 1);

    setTimeout(() => {
      const selectedElement = document.querySelector(
        ".solar-header.active.expand"
      );
      if (selectedElement) {
        selectedElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }, 100);
  }
}
function selectAreaOnSidebar(selectedSolarArea) {
  if (!showSolars.value) {
    toggleSolars();
  }
  let selectedSolarAreaIndex = props.solarGroupDetails.findIndex(
    (element) => element.plane.id === selectedSolarArea.plane.id
  );

  if (selectedSolar.value != selectedSolarAreaIndex) {
    selectedSolar.value = selectedSolarAreaIndex;

    setTimeout(() => {
      const selectedElement = document.querySelector(
        ".solar-header.active.expand"
      );
      if (selectedElement) {
        selectedElement.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    }, 100);
  } else {
    if (props.active == 6) {
      return;
    }

    selectedSolar.value = null;
    props.unselectSolarArea(true);
  }
}
function unselectSolarAreaOnSidebar() {
  selectedSolar.value = null;
}

defineExpose({
  colapseFromParent,
  handleCreateSolarGroup,
  selectAreaOnSidebar,
  unselectSolarAreaOnSidebar,
  showSolarFlag,
});

const removeModalOpen = ref(false);
const selectedAreaToDelete = ref(null);
const selectedAreaIndex = ref(null);

function openDeleteModal(solar) {
  selectedAreaToDelete.value = solar;
  selectedAreaIndex.value = props.completedAreas.indexOf(solar) + 1;
  removeModalOpen.value = true;
}

function closeRemoveModal() {
  removeModalOpen.value = false;
  selectedAreaToDelete.value = null;
  selectedAreaIndex.value = null;
}

function confirmDeleteSolarGroup() {
  if (selectedAreaToDelete.value) {
    handleDeleteSolarGroup(selectedAreaToDelete.value);
  }
  closeRemoveModal();
}

function handleDeleteSolarGroup(solar) {
  if (solar.type === "SOLAR_GROUP") {
    props.removeSolarGroup(solar);
  } else if (solar.type === "AREA") {
    props.removeSelectedArea(solar);
  } else {
    console.error("Unknown solar area type:", solar.type);
  }

  if (props.active === 2 || props.active === 3 || props.active === 6)
    props.unselectSolarArea();
}

function showSolarFlag() {
  if (props.solarGroupDetails.length) {
    props.setVisibilityInStore("solar_groups_visible", true);

    areasAreShown.value = true;
  }
}

// Watch for changes in props.areaDetails
watch(
  () => props.solarGroupDetails.length,
  (newLength) => {
    if (newLength > 0) {
      showSolars.value = true;
    }
  }
);

watch(
  () => props.objectIsloaded,

  (newValue) => {
    if (newValue) {
      if (props.config.solar_groups_visible === false) {
        // If solar_groups_visible is false in config, hide everything
        areasAreShown.value = false;
        props.hideAreas();
        showTrashIcon.value = false;
      } else {
        // If solar_groups_visible is true, show areas based on their individual states
        areasAreShown.value = true;

        showTrashIcon.value = true;
      }
    }
  }
);
</script>

<style lang="scss" scoped>
.main-wrapper {
  .pannel-wrapper {
    max-height: 31rem;
    overflow-y: scroll;
    -ms-overflow-style: none;
    scrollbar-width: none;
    .solar-wrapper {
      .solar-wrapper-header {
        position: sticky;
        top: 0;
        z-index: 1;
        background: #333537;
        max-height: 30px;
        display: flex;
        gap: 1rem;
        align-items: center;
        padding: 1rem 0;
        .section-header-right-side {
          cursor: pointer;
          display: flex;
          justify-content: space-between;
          width: 100%;
          align-items: center;
        }
        .section-header-right-side.inactiveGray {
          p {
            color: gray;
          }
          img {
            filter: grayscale(1) brightness(0.5); /* Initial gray color */
          }
        }
        p {
          font-family: "Roboto";
          font-size: 14px;
          font-style: normal;
          font-weight: bold;
          line-height: 19px;
          color: white;
          letter-spacing: 0.03em;
        }
        img {
          height: 12px;
          width: 18px;
          cursor: pointer;
        }
        .trash,
        .arrow {
          height: 20px;
          width: 20px;
        }
      }
      .solar-details-box {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        margin-bottom: 12px;
        margin-top: 6px;

        .solar-inside-wrapper {
          .solar-header.active.expand {
            border: 1px solid #23e7a5;
            border-radius: 10px 10px 0 0;
            border-bottom: none;
            margin-bottom: 1px;
            .anotation-image-wrapper {
              display: flex;
              gap: 1rem;
            }
          }
          .solar-header.active:hover {
            .anotation-image-wrapper {
              img {
                filter: brightness(0) invert(1); /* White color on hover */
              }
            }
          }
          .solar-header.expand {
            border: none;
            margin-bottom: 1px;
            border-radius: 10px 10px 0 0;
          }
          .solar-header:not(.active):hover {
            .anotation-image-wrapper.shown-solars {
              display: flex;
              gap: 1rem;
              .trash {
                filter: grayscale(1) brightness(1.7);
              }
              .eye {
                filter: grayscale(1) brightness(0.7);
              }
              .eye-closed {
                filter: grayscale(1) brightness(1.3);
              }
              img:hover {
                filter: brightness(0) invert(1); /* White color on hover */
              }
            }
          }
          .solar-header {
            max-height: 30px;
            background: #27292c;
            border: 1px solid #3b3c3d;
            display: flex;
            gap: 1rem;
            align-items: center;
            padding: 1rem 0.5rem;
            border-radius: 10px;
            display: flex;
            justify-content: space-between;

            .left-text {
              flex: 1;
              min-width: 0;
            }

            p.grayCustome {
              color: gray;
            }
            .anotation-image-wrapper {
              display: none;
            }
            .anotation-image-wrapper.hidden-solars {
              display: flex;
              img {
                filter: grayscale(1) brightness(1);
              }
              .eye-closed {
                filter: grayscale(1) brightness(1.3);
              }
              img:hover {
                filter: brightness(0) invert(1); /* White color on hover */
              }
            }

            p {
              cursor: pointer;
              color: white;
            }
            img {
              width: 18px;
              height: 18px;
              cursor: pointer;
              filter: brightness(0) invert(1);
            }
          }
          .solar-details {
            background: #27292c;
            border: 1px solid #23e7a5;
            border-top: none;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            padding: 5px 5px 8px 10px;
            .details-text {
              display: flex;
              justify-content: space-between;
            }
          }

          .solar-header.grayClass {
            margin-bottom: 1px;
            p {
              color: gray;
            }
            .eye {
              filter: grayscale(1) brightness(0.7);
            }
            .eye-closed {
              filter: grayscale(1) brightness(1.3);
            }
            img:hover {
              filter: brightness(0) invert(1); /* White color on hover */
            }
            .trash {
              display: none;
            }
            .anotation-image-wrapper {
              display: flex;
              gap: 1rem;
            }
          }
          .solar-header.active.grayClass {
            border: none;
            .anotation-image-wrapper {
              img {
                filter: brightness(0) invert(0.5); /* gray color  */
              }
              img:hover {
                filter: brightness(0) invert(1); /* White color on hover */
              }
            }
          }
          .solar-details.grayClass {
            border: none;
            p {
              color: gray;
            }
            span {
              color: gray;
            }
          }
        }
      }
    }
  }
}

.eye:hover {
  filter: brightness(0) invert(1); /* White color on hover */
}
.eye-closed {
  filter: grayscale(1) brightness(1);
}

@media (max-width: 1600px) {
  .main-wrapper {
    .pannel-wrapper {
      .solar-wrapper {
        .solar-details-box {
          display: flex;
          flex-direction: column;
          gap: 1rem;
          margin-bottom: 12px;
          margin-top: 6px;

          .solar-inside-wrapper {
            .solar-header.active {
              border: none;
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              border-bottom-right-radius: 0;
              border-bottom-left-radius: 0;
              img {
                width: 18px;
                height: 18px;
                cursor: pointer;
              }
            }
            .solar-details {
              p {
                font-size: 12px;
              }
            }
          }
        }
      }
    }
  }
}
</style>
