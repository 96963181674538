import { ElMessage } from "element-plus";

export const showTokenInvalid = function () {
  ElMessage.error({
    message:
      "Token invalid. Please go to project page to generate a new token.",
    showClose: true,
  });
  if (this.anonymousUser) {
    setTimeout(() => {
      window.location.href = `${window.runtimeConfig?.AFP_URL}`;
    }, 3500);
  } else {
    setTimeout(() => {
      window.location.href = `${window.runtimeConfig?.AFP_URL}project/${this.projectId}`;
    }, 3500);
  }
};
